import * as React from 'react'
import { ApolloError, useMutation } from '@apollo/client'

import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import { SendDirectDepositInfoRequestEmail } from './api-documents'
import { useTracking } from '@local/track'
import { useTranslation } from '@local/translations'
import { useDirectDepositIncompleteSectionStore } from '../stores'
import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

export type EmailSendModalProps = {
  /** When a single employee is sent as props, it overrides the selected employees held in state **/
  employee?: IncompleteDirectDepositsQueryEmployeeFragment
  onClose: VoidFunction
} & TestIdentifiable

/**
 * EmailSendModal component
 */
export const EmailSendModal: React.FunctionComponent<EmailSendModalProps> = ({
  onClose,
  employee,
  testId = 'email-send-modal'
}) => {
  const { t, Trans } = useTranslation()
  const { track } = useTracking()
  const screenSize = useScreenSize()

  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const [sendEmail] = useMutation(SendDirectDepositInfoRequestEmail)

  const setSelectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.setSelectedEmployees
  )
  const selectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.selectedEmployees
  )

  const firstName = getFirstNameOrNull(selectedEmployees, employee)
  const count = employee ? 1 : selectedEmployees.length

  const onCompleted = () => {
    !employee && setSelectedEmployees([])
    showSuccessSnackBar(
      t('email-send-modal.success', {
        count: count,
        firstName: firstName
      })
    )
    onClose()
  }

  const onError = (error: ApolloError) => {
    console.log('EmailSendModal', error)
    showErrorSnackBar(
      t('email-send-modal.error', {
        count: count,
        firstName: firstName
      })
    )
    onClose()
  }

  const onClickSend = () => {
    track('direct-deposit-bulk-action.incomplete-section.email-send')
    sendEmail({
      variables: {
        employees: employee
          ? [employee.id]
          : selectedEmployees.map((selectedEmployee) => selectedEmployee.id)
      },
      onCompleted: onCompleted,
      onError: onError
    })
  }

  return (
    <Modal
      testId={testId.toString()}
      closeButtonLabel={t('email-send-modal.button.close')}
      isOpen={true}
      onRequestClose={onClose}
      size='xxl'
    >
      <Modal.Header>
        {t('email-send-modal.header', {
          count: count,
          firstName: firstName
        })}
      </Modal.Header>
      <Modal.Body>
        <div className='space-y-6'>
          <div>
            {t('email-send-modal.description', {
              count: count,
              firstName: firstName
            })}
          </div>
          <div>
            <Trans
              i18nKey='email-send-modal.disclaimer'
              values={{ count: count }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='link'>
          {t('email-send-modal.button.cancel')}
        </Button>
        <Button
          onClick={onClickSend}
          size={screenSize < ScreenSize.MD ? 'lg' : 'sm'}
        >
          {t('email-send-modal.button', { count: count })}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
const getFirstNameOrNull = (
  selectedEmployees: IncompleteDirectDepositsQueryEmployeeFragment[],
  singleEmailSend: IncompleteDirectDepositsQueryEmployeeFragment | undefined
): string | null | undefined => {
  if (singleEmailSend != null) {
    return singleEmailSend.name.chosen
      ? singleEmailSend.name.chosen
      : singleEmailSend.name.first
  } else if (selectedEmployees.length === 1) {
    return selectedEmployees[0].name.chosen
      ? selectedEmployees[0].name.chosen
      : selectedEmployees[0].name.first
  }
  return null
}
