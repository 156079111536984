import * as React from 'react'
import { Pagination, usePagination } from '@toasttab/buffet-pui-pagination'
import { Select, SelectOption } from '@toasttab/buffet-pui-select'
import { CustomTFunction } from '@toasttab/buffet-pui-translation-utilities'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useTracking } from '@local/track'
import { useTranslation } from '@local/translations'
import { Cell, Foot, Row } from '@toasttab/buffet-pui-table'
import cx from 'classnames'

export const DEFAULT_TEST_ID = 'bulk-action-table-footer'

export type BulkActionTableFooterProps = {
  cellClassName?: string
  colSpan?: number
  limit: number
  offset: number
  onChangeLimit: (newLimit: number | null) => void
  onChangePage: (newPage: number) => void
  totalEmployeeCount: number
} & TestIdentifiable

/**
 * BulkActionTableFooter component
 * renders a drop-down list for selecting how many rows to view at a time, 10, 25, 50, 100, 200, all
 * renders a pagination component
 */
export const BulkActionTableFooter: React.FunctionComponent<
  BulkActionTableFooterProps
> = ({
  cellClassName,
  colSpan,
  limit: propsLimit,
  offset,
  onChangeLimit: propsOnChangeLimit,
  onChangePage: propsOnChangePage,
  totalEmployeeCount,
  testId = DEFAULT_TEST_ID
}) => {
  const { track } = useTracking()
  const [limit, setLimit] = React.useState<string>(propsLimit.toString())

  const getLimitOptions = useGetLimitOptions()
  const limitOptions = getLimitOptions(totalEmployeeCount)
  const onChangeLimit = React.useCallback(
    (newLimit: string) => {
      track('direct-deposit-bulk-action.incomplete-section.change-page-size')
      setLimit(newLimit)
      propsOnChangeLimit(Number(newLimit))
    },
    [propsOnChangeLimit, track]
  )

  const onChangePage = React.useCallback(
    (newPage: number) => {
      track('direct-deposit-bulk-action.incomplete-section.change-page')
      propsOnChangePage(newPage)
    },
    [propsOnChangePage, track]
  )

  const totalPageCount = Math.ceil(totalEmployeeCount / propsLimit)
  const paginationProps = usePagination({
    visiblePages: 7,
    currentPage: (offset + Number(limit)) / Number(limit) - 1,
    shouldWrap: false,
    onChangePage: onChangePage,
    totalPageCount: totalPageCount
  })

  return limitOptions.length > 0 ? (
    <Foot>
      <Row className='border-b-0'>
        <Cell className={cx('pl-0 pr-0', cellClassName)} colSpan={colSpan}>
          <div
            data-testid={testId}
            className='flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between'
          >
            <LimitSelect
              testId={testId}
              limitOptions={limitOptions}
              onChangeLimit={onChangeLimit}
              limit={limit}
            />
            {totalPageCount > 1 && (
              <Pagination
                testId={`${testId}-pagination`}
                {...paginationProps}
              />
            )}
          </div>
        </Cell>
      </Row>
    </Foot>
  ) : null
}

type LimitSelectProps = {
  limitOptions: any[]
  onChangeLimit: (newLimit: string) => void
  limit: string
} & TestIdentifiable

const LimitSelect: React.FunctionComponent<LimitSelectProps> = ({
  testId,
  limitOptions,
  onChangeLimit,
  limit
}) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-row gap-x-3 items-center'>
      {t('direct-deposit-incomplete.table.footer.view-label')}
      <Select
        testId={`${testId}-select`}
        containerClassName='w-full md:w-fit'
        hideLabel={true}
        label={t('direct-deposit-incomplete.table.footer.view-label')}
        options={limitOptions}
        onChange={onChangeLimit}
        value={limit}
      />
    </div>
  )
}

const useGetLimitOptions = (): ((totalCount: number) => SelectOption[]) => {
  const { t } = useTranslation()
  return (totalCount) => {
    let viewOptions: SelectOption[] = []

    if (totalCount <= 10) {
      return viewOptions
    }

    viewOptions.push(getLimitOption(t, 10))

    if (totalCount > 25) {
      viewOptions.push(getLimitOption(t, 25))
    }

    if (totalCount > 50) {
      viewOptions.push(getLimitOption(t, 50))
    }

    if (totalCount > 100) {
      viewOptions.push(getLimitOption(t, 100))
    }

    if (totalCount <= 200) {
      viewOptions.push({
        label: t('direct-deposit-incomplete.table.footer.view-all-option'),
        value: '200'
      })
    } else {
      viewOptions.push(getLimitOption(t, 200))
    }

    return viewOptions
  }
}

const getLimitOption = (
  t: CustomTFunction<any, any>,
  numberOfEmployeesToView: number
): SelectOption => {
  return {
    label: t('direct-deposit-incomplete.table.footer.view-option', {
      numberOfEmployeesToView: numberOfEmployeesToView.toString()
    }),
    value: numberOfEmployeesToView.toString()
  }
}
