import React from 'react'

import { Cell } from '@toasttab/buffet-pui-table'
import { Badge } from '@toasttab/buffet-pui-badge'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { useTranslation } from '@local/translations'

import { isValidEmail } from '../utils'

type Props = Pick<IncompleteDirectDepositsQueryEmployeeFragment, 'email'>

export const EmailCell: React.FunctionComponent<Props> = (props) => {
  return (
    <Cell className='align-middle text-nowrap text-ellipsis overflow-hidden'>
      <EmailComponent {...props} />
    </Cell>
  )
}

export const EmailComponent: React.FunctionComponent<Props> = ({ email }) => {
  const { t } = useTranslation()
  const isEmailValid = isValidEmail(email)
  return isEmailValid ? (
    email
  ) : (
    <Badge className='text-ellipsis' color='error'>
      {t('direct-deposit-incomplete.table.email.invalid')}
    </Badge>
  )
}
