import * as React from 'react'
import { useTranslation } from '@local/translations'
import { EmptyStateCard } from '@local/empty-state-card'

export const EmptyStateNoDirectDeposits: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <EmptyStateCard subtitle={t('direct-deposit-incomplete.empty-state')} />
  )
}
