import * as React from 'react'
import {
  cdnTranslationLoaderFactory,
  createTranslationUtilities
} from '@toasttab/buffet-pui-translation-utilities'
import defaultStringsEnUS from './defaultStrings-en_US.json'
import { useIntlProps } from 'banquet-runtime-modules'

const defaultStrings = defaultStringsEnUS

export type AppStrings = typeof defaultStrings

const spaName = 'bulk-import-direct-deposit-spa'

// Call in module scope to cache loaded translations
// across single spa mount / unmount cycles
export const { useTranslation, BuffetTranslationProvider } =
  createTranslationUtilities<AppStrings>({
    spaName,
    translationLoaderFn: ({ cdnUrl }) =>
      cdnTranslationLoaderFactory<AppStrings>({
        cdnUrl,
        defaultStrings,
        namespace: spaName
      })
  })

export const TranslationProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { i18next } = useIntlProps()

  return (
    <BuffetTranslationProvider i18n={i18next}>
      {children}
    </BuffetTranslationProvider>
  )
}
