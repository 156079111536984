import React from 'react'
import { Cell } from '@toasttab/buffet-pui-table'

import { DirectDepositAllocation } from '@local/grouter'
import { useTranslation } from '@local/translations'

export const DepositAccountTypeCell: React.FunctionComponent<
  Pick<DirectDepositAllocation, 'depositAccountType'>
> = ({ depositAccountType }) => {
  const { t } = useTranslation()
  return (
    <Cell className='align-middle'>
      {t(`direct-deposits.table.account-type.${depositAccountType}`)}
    </Cell>
  )
}
