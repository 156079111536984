import React from 'react'

import {
  Head,
  HeadingCell,
  Row,
  SortDirection as BuffetSortDirection,
  SortIndicator
} from '@toasttab/buffet-pui-table'

import {
  IncompleteDirectDepositSortColumn,
  SortDirection
} from '@local/grouter'
import { useTracking } from '@local/track'
import { useTranslation } from '@local/translations'

import { DirectDepositIncompleteTableProps } from '../DirectDepositIncompleteTable'
import { SelectAllCheckbox } from './components'

export const DirectDepositIncompleteTableHead: React.FunctionComponent<
  DirectDepositIncompleteTableProps
> = ({
  visibleEmployees,
  sortColumn: propsSortColumn,
  sortDirection: propsSortDirection,
  onChangeSort: propsOnChangeSort
}) => {
  const { t } = useTranslation()
  const { track } = useTracking()
  const [sortedColumn, setSortedColumn] =
    React.useState<IncompleteDirectDepositSortColumn>(propsSortColumn)
  const [sortedDirection, setSortedDirection] =
    React.useState<SortDirection>(propsSortDirection)

  const getBuffetSortDirection = React.useCallback(
    (
      currentColumn: IncompleteDirectDepositSortColumn,
      sortedColumn: IncompleteDirectDepositSortColumn,
      sortedDirection: SortDirection
    ): BuffetSortDirection => {
      if (currentColumn === sortedColumn) {
        return sortedDirection === 'ASC'
          ? BuffetSortDirection.ASC
          : BuffetSortDirection.DESC
      }
      return BuffetSortDirection.NONE
    },
    []
  )

  const onChangeSort = React.useCallback(
    (
      newSortColumn: IncompleteDirectDepositSortColumn,
      sortedColumn: IncompleteDirectDepositSortColumn,
      sortedDirection: SortDirection
    ) => {
      track('direct-deposit-bulk-action.incomplete-section.change-sort')
      let newSortDirection: SortDirection = 'ASC'
      if (newSortColumn === sortedColumn && sortedDirection === 'ASC') {
        newSortDirection = 'DESC'
      }
      setSortedColumn(newSortColumn)
      setSortedDirection(newSortDirection)
      propsOnChangeSort(newSortColumn, newSortDirection)
    },
    [propsOnChangeSort, track]
  )

  return (
    <Head>
      <Row>
        <HeadingCell className='w-14'>
          <SelectAllCheckbox employees={visibleEmployees} />
        </HeadingCell>
        <HeadingCell
          isSorted={sortedColumn === 'NAME'}
          role='button'
          onClick={() => {
            onChangeSort('NAME', sortedColumn, sortedDirection)
          }}
        >
          {t('direct-deposit-incomplete.table.heading.name')}
          <SortIndicator
            direction={getBuffetSortDirection(
              'NAME',
              sortedColumn,
              sortedDirection
            )}
          />
        </HeadingCell>
        <HeadingCell>
          {t('direct-deposit-incomplete.table.heading.email')}
        </HeadingCell>
        <HeadingCell
          isSorted={sortedColumn === 'LAST_CONTACTED'}
          role='button'
          onClick={() => {
            onChangeSort('LAST_CONTACTED', sortedColumn, sortedDirection)
          }}
        >
          {t('direct-deposit-incomplete.table.heading.last-contacted')}
          <SortIndicator
            direction={getBuffetSortDirection(
              'LAST_CONTACTED',
              sortedColumn,
              sortedDirection
            )}
          />
        </HeadingCell>
        <HeadingCell className='w-14' />
      </Row>
    </Head>
  )
}
