import * as React from 'react'
import { Body, Head, HeadingCell, Row, Table } from '@toasttab/buffet-pui-table'
import {
  BulkActionTableFooter,
  BulkActionTableFooterProps
} from '@local/bulk-action-table-footer'
import { DirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { StackedDataCell } from './StackedDataCell'
import cx from 'classnames'
import { SelectAllCheckbox } from '../../../../../direct-deposit-incomplete/direct-deposit-incomplete-card/components/direct-deposit-incomplete-table/table-head'
import { DirectDepositIncompleteCardHeader } from '../../../../../direct-deposit-incomplete/direct-deposit-incomplete-card/DirectDepositIncompleteCardHeader'
import { DirectDepositsDataViewHeader } from '../../DirectDepositsDataView'

export type DirectDepositsTableMobileProps = {
  visibleEmployees: DirectDepositsQueryEmployeeFragment[]
} & BulkActionTableFooterProps

export const DirectDepositsTableMobile: React.FunctionComponent<
  DirectDepositsTableMobileProps
> = (props) => {
  // for borders to be sticky, table must be set to border-separate, and borders must be set on the cell instead of the row
  const stickyBorderClassName =
    'border-default border-t border-b-2 border-darken-12'
  return (
    <Table variant='plain' className='border-separate'>
      <Head className='sticky top-[122px] z-10 bg-white'>
        <Row>
          <HeadingCell
            className={cx('pt-2 pb-2 pl-0 pr-0', stickyBorderClassName)}
          >
            <DirectDepositsDataViewHeader
              className='h-12 place-content-center'
              visibleEmployeeCount={props.visibleEmployees.length}
              totalCount={props.totalEmployeeCount}
            />
          </HeadingCell>
        </Row>
      </Head>
      <Body>
        {props.visibleEmployees.map((employee) => {
          return (
            <Row key={employee.id}>
              <StackedDataCell employee={employee} />
            </Row>
          )
        })}
      </Body>
      <BulkActionTableFooter cellClassName='pt-6 pb-0' colSpan={5} {...props} />
    </Table>
  )
}
