import React from 'react'
import { useTranslation } from '@local/translations'
import { BulkActionLinkSection } from '@local/bulk-action-section'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { trackBulkImportPortal } from '@local/track'
import { useUser } from '@toasttab/ec-session'

export const BulkImportSection: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const user = useUser()
  const isToastUser = user.isUserSuperAdminLight || user.isUserSuperAdmin

  return isToastUser ? (
    <BulkActionLinkSection
      {...trackBulkImportPortal('legacy-bulk-import')}
      title={t('bulk-import.title')}
      subtitle={t('bulk-import.subtitle')}
      action={{
        onClick: () => window.location.assign('DirectDepositImport'),
        icon: <ChevronRightIcon accessibility='decorative' />
      }}
    />
  ) : null
}
