import React from 'react'
import { BulkActionDisclosureSection } from '@local/bulk-action-section'
import { useTranslation } from '@local/translations'
import { DirectDepositsCard } from './direct-deposit-card'

export const DirectDepositsSection: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <BulkActionDisclosureSection
      title={t('direct-deposits.title')}
      subtitle={t('direct-deposits.subtitle')}
    >
      <DirectDepositsCard />
    </BulkActionDisclosureSection>
  )
}
