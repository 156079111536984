import React from 'react'
import cx from 'classnames'

import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { useTranslation } from '@local/translations'

import { useDirectDepositIncompleteSectionStore } from '../../../../../stores'
import { isValidEmail } from '../../utils'

type SelectAllCheckboxProps = {
  employees: IncompleteDirectDepositsQueryEmployeeFragment[]
}

export const SelectAllCheckbox: React.FunctionComponent<
  SelectAllCheckboxProps
> = ({ employees }) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()

  const selectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.selectedEmployees
  )
  const setSelectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.setSelectedEmployees
  )

  const selectableEmployees = employees
    ? employees.filter((employee) => isValidEmail(employee?.email))
    : []
  const isAnySelected = selectedEmployees.length > 0
  const isAllSelected =
    selectableEmployees.length > 0 &&
    selectableEmployees.length === selectedEmployees.length

  const onChangeSelectAll = () => {
    if (isAllSelected) {
      setSelectedEmployees([])
    } else {
      setSelectedEmployees(selectableEmployees ?? [])
    }
  }

  return (
    <Checkbox
      checked={isAnySelected}
      containerClassName={cx({ 'p-0': screenSize < ScreenSize.MD })}
      disabled={selectableEmployees.length < 1}
      hideLabel
      indeterminate={isAnySelected && !isAllSelected}
      label={t('direct-deposit-incomplete.table.heading.selection')}
      onChange={onChangeSelectAll}
    />
  )
}
