import React from 'react'
import cx from 'classnames'
import { Head, HeadingCell, Row } from '@toasttab/buffet-pui-table'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { SortArrowsIcon } from '@toasttab/buffet-pui-icons'
import { IncompleteDirectDepositSortColumn } from '@local/grouter'
import { SortModal } from '@local/sort-modal'
import { useTranslation } from '@local/translations'
import { DirectDepositIncompleteCardHeader } from '../../../DirectDepositIncompleteCardHeader'
import { DirectDepositIncompleteTableProps } from '../DirectDepositIncompleteTable'
import { SelectAllCheckbox } from '../table-head'

export const DirectDepositIncompleteTableHeadMobile: React.FunctionComponent<
  Pick<
    DirectDepositIncompleteTableProps,
    | 'sortColumn'
    | 'sortDirection'
    | 'onChangeSort'
    | 'totalEmployeeCount'
    | 'visibleEmployees'
  >
> = ({
  sortColumn,
  sortDirection,
  onChangeSort,
  totalEmployeeCount,
  visibleEmployees
}) => {
  const { t } = useTranslation()
  // for borders to be sticky, table must be set to border-separate, and borders must be set on the cell instead of the row
  const stickyBorderClassName =
    'border-default border-t border-b-2 border-darken-12'
  const [isSortModalOpen, setIsSortModalOpen] = React.useState(false)
  return (
    <>
      {isSortModalOpen && (
        <SortModal
          isOpen={isSortModalOpen}
          onRequestClose={() => setIsSortModalOpen(false)}
          currentSort={{ column: sortColumn, direction: sortDirection }}
          sortColumns={[
            { value: 'NAME', label: 'Name' },
            { value: 'LAST_CONTACTED', label: 'Last emailed' }
          ]}
          resetButton={{
            onClick: () => {
              onChangeSort('NAME', 'ASC')
              setIsSortModalOpen(false)
            }
          }}
          applyButton={{
            onClick: (sortColumn, sortDirection) => {
              onChangeSort(
                sortColumn as IncompleteDirectDepositSortColumn,
                sortDirection === 'NONE' ? 'DESC' : sortDirection
              )
              setIsSortModalOpen(false)
            }
          }}
        />
      )}
      <Head className='sticky top-[144px] z-10 bg-white'>
        <Row>
          <HeadingCell
            className={cx('pt-2 pb-2 pl-0 pr-0', stickyBorderClassName)}
          >
            <SelectAllCheckbox employees={visibleEmployees} />
          </HeadingCell>
          <HeadingCell
            className={cx('pt-2 pb-2 pr-0', stickyBorderClassName)}
            colSpan={2}
          >
            <DirectDepositIncompleteCardHeader // todo onclick translation for aria label
              secondaryAction={
                <IconButton
                  aria-label={t('direct-deposit-incomplete.button.sort-modal')}
                  className='text-secondary'
                  cropToIcon={true}
                  icon={<SortArrowsIcon aria-label='sort' />}
                  onClick={() => setIsSortModalOpen(true)}
                />
              }
              totalCount={totalEmployeeCount}
            />
          </HeadingCell>
        </Row>
      </Head>
    </>
  )
}
