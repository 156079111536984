import React from 'react'

import {
  Currency,
  formatCurrency,
  formatPercent
} from '@toasttab/buffet-pui-number-utilities'
import { Cell } from '@toasttab/buffet-pui-table'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

import {
  DirectDepositsQueryEmployeeFragment,
  SplitDepositType
} from '@local/grouter'
import { StackedDataCellTextPair } from '@local/stacked-data-cell-text-pair'
import { useTranslation } from '@local/translations'
import { NameComponent, SequenceContent, useFormatAmount } from '../cells'

type StackedDataCellProps = {
  employee: DirectDepositsQueryEmployeeFragment
}

export const StackedDataCell: React.FunctionComponent<StackedDataCellProps> = ({
  employee
}) => {
  const { t } = useTranslation()
  const showSequence = employee.allocations.length > 1
  const formatAmount = useFormatAmount()

  return (
    <Cell className='flex flex-col items-start border-default border-b space-y-4 py-4'>
      <NameComponent
        id={employee.id}
        name={employee.name}
        email={employee.email}
      />
      {employee.allocations.map((allocation, index) => (
        <div className='w-full flex'>
          <StackedDataCellTextPair
            label={t('direct-deposits.table.heading.account-type')}
            value={t(
              `direct-deposits.table.account-type.${allocation.depositAccountType}`
            )}
          />
          <StackedDataCellTextPair
            label={t('direct-deposits.table.heading.amount')}
            value={formatAmount(
              allocation.amount,
              employee.allocations[0].splitDepositType,
              employee.allocations.length > 1 &&
                index === employee.allocations.length - 1
            )}
          />
          {showSequence && (
            <StackedDataCellTextPair
              label={t('direct-deposits.table.heading.mobile.sequence')}
              value={<SequenceContent sequence={allocation.sequence} />}
            />
          )}
        </div>
      ))}
    </Cell>
  )
}
