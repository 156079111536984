import * as React from 'react'
import cx from 'classnames'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { DirectDepositsQuery } from '@local/grouter'
import { useTranslation } from '@local/translations'
import {
  DirectDepositsTable,
  DirectDepositsTableMobile
} from './direct-deposits-table'

type DirectDepositsDataViewProps = {
  data: DirectDepositsQuery
  limit: number
  setLimit: (newLimit: number) => void
  offset: number
  setOffset: (newOffset: number) => void
}

export const DirectDepositsDataView: React.FunctionComponent<
  DirectDepositsDataViewProps
> = ({ data, limit, setLimit, offset, setOffset }) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()

  const onChangeLimit = React.useCallback((newLimit: number | null) => {
    setOffset(0)
    setLimit(newLimit ?? 10)
  }, [])

  const onChangePage = React.useCallback((newPage: number, limit: number) => {
    setOffset(limit * newPage)
  }, [])

  return screenSize < ScreenSize.MD ? (
    <div className='grid gap-y-6'>
      <DirectDepositsTableMobile
        limit={limit}
        offset={offset}
        onChangeLimit={onChangeLimit}
        onChangePage={(newPage: number) => onChangePage(newPage, limit)}
        totalEmployeeCount={data?.completeDirectDeposits?.totalCount ?? 0}
        visibleEmployees={data?.completeDirectDeposits?.employees}
      />
    </div>
  ) : (
    <CardContainer className='flex flex-col gap-y-6' noElevation>
      <DirectDepositsDataViewHeader
        visibleEmployeeCount={data?.completeDirectDeposits?.employees.length}
        totalCount={data?.completeDirectDeposits?.totalCount ?? 0}
      />
      <DirectDepositsTable
        limit={limit}
        offset={offset}
        onChangeLimit={onChangeLimit}
        onChangePage={(newPage: number) => onChangePage(newPage, limit)}
        totalEmployeeCount={data?.completeDirectDeposits?.totalCount ?? 0}
        visibleEmployees={data?.completeDirectDeposits?.employees}
      />
    </CardContainer>
  )
}

export const DirectDepositsDataViewHeader: React.FunctionComponent<{
  className?: string
  visibleEmployeeCount?: number
  totalCount?: number
}> = ({ className, visibleEmployeeCount, totalCount }) => {
  const { t } = useTranslation()
  return (
    <div className={cx('font-semibold', className)}>
      {t('direct-deposits.showing', {
        showingCount: visibleEmployeeCount || 0,
        totalCount: totalCount ?? 0
      })}
    </div>
  )
}
