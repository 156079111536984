import * as React from 'react'
import { BrowserRouter, RouteObject, useRoutes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Portal, PortalProvider } from '@toasttab/ec-layout'
import { useUser } from '@toasttab/ec-session'

import { client } from '@local/api/client'
import { TranslationProvider } from '@local/translations'
import { UnauthorizedPage } from '@local/unauthorized-page'

import { DirectDepositBulkActionPage } from './DirectDepositBulkActionPage'

const EntryRoute: RouteObject = {
  path: '/mvc/:client/Company/Setup/DirectDepositBulkAction',
  element: <DirectDepositBulkActionPage />
}

const wildcard = { path: '*', element: null }

const App = () => {
  const user = useUser()
  const routes = useRoutes([EntryRoute, wildcard])

  if (
    user.isUserSuperAdminLight ||
    user.isUserSuperAdmin ||
    user.isPureUserHRPlusOrAbove
  ) {
    return routes
  }

  return <UnauthorizedPage />
}

const AppBootstrap = () => {
  return (
    <TranslationProvider>
      <SnackBarProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <PortalProvider value='data-ec-ecs'>
              <Portal
                id='direct-deposit-bulk-action'
                component={
                  <React.Suspense
                    fallback={
                      <div>
                        <MerryGoRound className='mx-auto mt-7' />
                      </div>
                    }
                  >
                    <App />
                  </React.Suspense>
                }
                skipRenderIfNotFound={false}
              />
            </PortalProvider>
          </BrowserRouter>
        </ApolloProvider>
      </SnackBarProvider>
    </TranslationProvider>
  )
}

export { App, AppBootstrap }
