import * as React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { useTranslation } from '@local/translations'

export type ErrorCardProps = {
  onClick: VoidFunction
} & TestIdentifiable

/**
 * ErrorCard component
 */

export const ErrorCard: React.FunctionComponent<
  React.PropsWithChildren<ErrorCardProps>
> = ({ testId = 'error-card', onClick }) => {
  const { t, Trans } = useTranslation()

  const customerCareUrl =
    'https://central.toasttab.com/s/article/How-to-Submit-a-Ticket-to-Customer-Care'

  return (
    <CardContainer
      testId={testId}
      className='flex flex-col items-center pt-10 px-10 pb-10 space-y-6'
    >
      <div className='flex flex-col items-center space-y-4'>
        <WarningOutlineIcon
          testId={`${testId}-icon`}
          size='md'
          accessibility='decorative'
          className='text-secondary'
        />

        <span
          data-testid={`${testId}-title`}
          className='type-default text-center'
        >
          {t('error-card.title')}
        </span>

        <span
          data-testid={`${testId}-subtitle`}
          className='type-default text-center'
        >
          <Trans
            i18nKey='error-card.subtitle'
            components={[
              <a
                key='customerCareLink'
                data-testid={`${testId}-subtitle-link`}
                className='inline-link cursor-pointer'
                href={customerCareUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('error-card.subtitle')}
              </a>
            ]}
          />
        </span>
      </div>

      <Button testId={`${testId}-button`} variant='secondary' onClick={onClick}>
        {t('error-card.button')}
      </Button>
    </CardContainer>
  )
}
