import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Modal, ModalProps } from '@toasttab/buffet-pui-modal'
import { RadioGroup } from '@toasttab/buffet-pui-radio-group'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useTranslation } from '@local/translations'

type SortDirection = 'ASC' | 'DESC' | 'NONE'

export type SortModalProps = {
  /** header for the entire modal **/
  header?: string
  /** the column and direction by which the data is currently sorted **/
  currentSort?: {
    column: string
    direction: SortDirection
  }
  /** the available columns by which the table data can be sorted **/
  sortColumns: {
    value: string
    label: string
  }[]
  /** the reset button label and action to be taken when clicked **/
  resetButton: {
    label?: string
    onClick: VoidFunction
  }
  /** the apply button label and action to be taken when clicked **/
  applyButton: {
    label?: string
    onClick: (sortColumn: string, sortDirection: SortDirection) => void
  }
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'> &
  TestIdentifiable

/**
 * SortModal component
 * Usage: sort support for tables responding to small screens
 */
export const SortModal: React.FunctionComponent<SortModalProps> = ({
  testId = 'sort-modal',
  isOpen,
  onRequestClose,
  header,
  currentSort,
  sortColumns,
  resetButton,
  applyButton
}) => {
  const { t } = useTranslation()

  const [sortColumn, setSortColumn] = React.useState<string>(
    currentSort?.column || ''
  )
  const sortColumnOptions = React.useMemo(
    () =>
      sortColumns.map((option) => ({
        ...option,
        checked: sortColumn === option.value
      })),
    [sortColumns, sortColumn]
  )

  const [sortDirection, setSortDirection] = React.useState<SortDirection>(
    currentSort?.direction || 'NONE'
  )
  const sortDirectionOptions = React.useMemo(
    () => [
      { value: 'ASC', label: 'Ascending', checked: sortDirection === 'ASC' },
      { value: 'DESC', label: 'Descending', checked: sortDirection === 'DESC' }
    ],
    [sortDirection]
  )

  return (
    <Modal
      testId={testId.toString()}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Modal.Header>{header ?? t('sort-modal.header')}</Modal.Header>
      <Modal.Body>
        <RadioGroup
          options={sortColumnOptions}
          onChange={(e) => setSortColumn(e?.target?.value)}
        />
        <DividingLine />
        <RadioGroup
          options={sortDirectionOptions}
          onChange={(e) => setSortDirection(e?.target?.value as SortDirection)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={resetButton.onClick} size='lg' variant='link'>
          {resetButton.label ?? t('sort-modal.button.reset')}
        </Button>
        <Button
          onClick={() => applyButton.onClick(sortColumn, sortDirection)}
          size='lg'
        >
          {applyButton.label ?? t('sort-modal.button.apply')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
